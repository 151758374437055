.loader-wrapper {
  perspective: 1000px;
  perspective-origin: top center;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  transform-style: preserve-3d;
  height: 128px;
  width: 128px;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: rotate-loader 3s infinite linear forwards;
}

.loader-image {
  display: block;
  position: absolute;
}

.loader-text {
  animation: animate-text 3s infinite alternate linear;
  text-align: center;
}

.loader-image-1 {
  clip-path: polygon(50% 0%, 100% 25%, 50% 50%, 0 25%);
  animation: animate-1 3s infinite alternate linear;
}
.loader-image-2 {
  clip-path: polygon(100% 25%, 100% 75%, 50% 100%, 50% 50%);
  animation: animate-2 3s infinite alternate linear;
}
.loader-image-3 {
  clip-path: polygon(50% 50%, 50% 100%, 0% 75%, 0% 25%);
  animation: animate-3 3s infinite alternate linear;
}

@keyframes animate-1 {
  0% {
    transform: translate3d(0, 0, 0);
  }
  25% {
    transform: translate3d(0, 0, 0);
  }
  50% {
    transform: translate3d(0px, 0px, -150px);
  }
  75% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes animate-2 {
  0% {
    transform: translate3d(0, 0, 0);
  }
  25% {
    transform: translate3d(0, 0, 0);
  }
  50% {
    transform: translate3d(30px, 30px, -50px);
  }
  75% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes animate-3 {
  0% {
    transform: translate3d(0, 0, 0);
  }
  25% {
    transform: translate3d(0, 0, 0);
  }
  50% {
    transform: translate3d(-30px, 30px, -50px);
  }
  75% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes animate-text {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes rotate-loader {
  0% {
    transform: rotate(0);
  }
  75% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
