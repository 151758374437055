.search_input {
    width: "100%";
    padding-left: "24px";
    outline: "none";
    height: "64px";
    background: "#FFFFFF";
    border: "none";
    box-shadow: "0 1px 4px 0 rgba(0,0,0,0.3)";
}

.msglist_msg_item {
    height: 70px;
    padding: 15px;
    padding-top: 10px;
    cursor: pointer;
    background-color: #f9f9f9;
    border-bottom: 2px solid;
    border-bottom-color: #a9a9a985;
}

.msglist_img_thumbnail {
    position: absolute;
    float: left;
    border-radius: 20px;
    height: 40px;
    width: 40px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.msglist_user_name {
    color: #25252b;
    font-size: 14px;
    font-family: "Lato", sans-serif;
    font-weight: 500;
    line-height: 18px;
    text-transform: capitalize;
    letter-spacing: 0.2px;
}

.msglist_user_name.read {
    color: #686871;
    text-transform: capitalize;
    font-family: "Lato", sans-serif;
}

.msglist_attachment_icon {
    margin-right: 10px;
}

.msglist_msg_datetime {
    float: right;
    margin-top: 5px;
    color: #686871;
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 0.2px;
    line-height: 18px;
}

.msglist_message {
    color: #25252b;
    font-family: "Lato", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    height: 54;
    padding-top: 10;
}

.msglist_search {
    width: 100%;
    padding-left: 24px;
    outline: none;
    height: 64px;
    background-color: #ffffff;
    border: none;
    margin-bottom: 5px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
}

.msglist_container {
    height: 100vh;
    background-color: #f9f9f9;
    box-shadow: 2px 3px 4px 0 rgba(0, 0, 0, 0.3)
}

.read {
    color: #686871;
}

.selected {
    background-color: #ffffff;
}

.newMessageIndicator {
    background-color: #eb028c;
    height: 8px;
    width: 8px;
    display: inline-block;
    float: right;
    margin-right: 10;
    border-radius: 90px;
    position: relative;
}

.msgDtl_img_thumbnail {
    display: inline-block;
    height: 20px;
    width: 20px;
    background-position: center;
    background-size: contain;
    border-radius: 20px;
    background-repeat: no-repeat;
    margin-right: 20px;
    position: relative;
    top: 4px;
}

.msgDtl_img_thumbnail_outer {
    display: inline-block;
    height: 30px;
    width: 30px;
    margin-right: 20px;
    position: relative;
}

.msgDtl_img_thumbnail_inner {
    height: 20px;
    width: 20px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 20px;
    position: relative;
    top: 5px;
    left: 5px;
}

.msgDtl_label_div {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
}

.msgDtl_DarkContent {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.23px;
    color: #25252b;
    white-space: pre-wrap;
}

.msgDtl_Content {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #686871;
    white-space: pre-wrap;
}

.msgcontact_header {
    background: #226c96;
    height: 64px;
    width: 100%;
    box-shadow: "0 1px 4px 0 rgba(0,0,0,0.3)";
}

.msgdisplay_container {
    height: 100vh;
}

.msgcontact_img {
    position: absolute;
    float: left;
    border-radius: 25px;
    height: 45px;
    width: 45px;
    margin-top: 10px;
    margin-left: 25px;
    margin-right: 15px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.msgcontact_name {
    left: 110px;
    position: absolute;
    align-self: center;
    color: white;
    text-transform: capitalize;
    margin-left: -25px;
    line-height: 2;
    font-weight: 600;
    font-size: 20px;
    padding-top: 2px;
    font-family: "Lato", sans-serif;
}

.msgblock_from {
    padding: 8px;
    padding-right: 12px;
    background-color: #8cc63f6b;
    border-radius: 5px;
    position: relative;
    margin-left: 25px;
    float: left;
}

.msgblock_from::before {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: -9px;
    top: 0px;
    border: 6px solid;
    border-color: #cfe7ae #cfe7ae transparent transparent;
}

.msgblock_to {
    padding: 8px;
    padding-right: 12px;
    background-color: #02669e40;
    border-radius: 5px;
    position: relative;
    margin-right: 25px;
    float: right;
}

.msgblock_to::after {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    top: 0px;
    right: -9px;
    border: 6px solid;
    border-color: #bfd8e7 transparent transparent #bfd8e7;
}

.msgdetail_footer {
    padding-top: 1vh;
    align-self: center;
    background-color: #c1c1c1c1;
    height: 7vh;
    bottom: 0px;
    position: absolute;
    padding-left: 25px;
    padding-right: 20px;
    width: 100%;
}

.msgdetail_footer textarea {
    border-radius: 5px;
    height: 5vh;
    overflow: scroll;
    resize: none;
    border-radius: 20px;
    padding: 5px;
    padding-left: 15px;
}

.msgdetail_footer button {
    width: 100%;
    height: 5vh;
}

.msgdisplay {
    height: 100%;
}

.msgdisplay_messages {
    display: flex;
    flex-direction: column-reverse;
    overflow: scroll;
    height: 84vh;
}

.msgdisplay::-webkit-scrollbar {
    display: none;
}

.msgcontact_list {
    overflow: scroll;
    height: 90%;
}

.msgcontact_list::-webkit-scrollbar {
    display: none;
}

.msgblock_time {
    position: relative;
    top: 5px;
    float: right;
    margin-top: 5px;
    margin-right: -5px;
    margin-left: 10px;
    margin-bottom: -5px;
    font-size: 12px;
    font-family: "Lato", sans-serif;
    font-weight: 500;
}

.msglist_avatar {
    text-align: center;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    display: inline-block;
    padding-top: 10px;
    background-color: red;
    background-position: center;
    color: white;
    font-size: x-large;
    font-weight: 500;
    line-height: 30px;
    vertical-align: middle;
}

.msglist_startConversation {
    height: 55px;
    border: none;
    border-radius: 30px;
    color: white;
    background: #226c96;
    font-size: 32px;
    position: absolute;
    bottom: 40px;
    margin-left: 44px;
    width: 55px;
    font-weight: bold;
    font-family: "Lato", sans-serif;
}

button:focus {
    border: none;
    outline: none;
}

.contactlist_item {
    height: 60px;
    padding: 7px;
    border-bottom: 1px solid #c8c8c8;
}

.contactlist_name {
    color: #25252b;
    font-size: 16px;
    text-transform: capitalize;
    font-family: "Lato", sans-serif;
    font-weight: 500;
    line-height: 42px;
    letter-spacing: 0.2px;
}

.msgblock {
    padding: 10px;
}

.info {
    position: absolute;
    text-align: center;
    font-size: 18px;
    top: 50vh;
    left: 0;
    bottom: 0;
    right: 0;
    height: 40px;
}

.placeholder {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 40%;
}